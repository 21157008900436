import Centipede from './Centipede';

function App() {
  return (
    <div className="App">
      <Centipede/>
    </div>
  );
}

export default App;
